@import "../configurations";

.features {
  margin-top: 0 !important;
  color: #282d47;

  section {
    padding: 0 5%;
    > div {
      max-width: 1260px;
      margin: 0 auto;
    }

    h2 {
      font-size: 40px;
      line-height: 48px;
      font-weight: 500;
      margin: 0 auto 30px auto;
    }

    h3 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 400;
      margin: 0 auto 10px;
    }

    .desc {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      color: #7e8890;

      a {
        color: #4a90e2;
        text-decoration: underline;
      }
    }

    .divide-content-wrapper {
      margin: 0 auto;
      display: inline-block;

      > * {
        float: left;
      }

      &:after {
        clear: both;
        content: " ";
        display: block;
      }
    }

    dl {
      text-align: left;

      dt {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 6px;
      }
      dd {
        font-size: 15px;
        line-height: 22px;
        font-weight: 300;
        margin-bottom: 50px;
        color: #7e8890;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .features__headline-wrapper {
    padding: 0;
    margin: 0;
    background-image: url('https://t1.kakaocdn.net/kakaocorp/admin/main-banner/1462');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .features__headline {
    max-width: 100%;
    padding: 210px 5% 90px;
    background-image: linear-gradient(287deg, #1c7dff, #57a2ff);
    opacity: 0.9;
    color: white;
    font-size: 20px;
    font-weight: 500;

    @include mobile() {
      padding-top: 130px;
      padding-bottom: 0;
    }

    .desc {
      color: white;
      line-height: 30px;
    }
    .icon-movie-play {
      margin: 64px 0 5px;;
    }
  }

  .features__companies {
    padding-top: 90px;
    padding-bottom: 110px;
    border-bottom: 1px solid #eeeeee;
    h3 { margin-bottom: 60px; }
    img {
      width: 100%;
      max-width: 1019px;
    }
  }

  .features__histories {
    padding-top: 100px;
    padding-bottom: 100px;

    .desc {
      margin-bottom: 55px;
    }

    img {
      box-sizing: border-box;
      padding: 0 45px;
      max-width: 540px;
      width: 100%;
    }

    dl {
      padding: 25px 40px;
      max-width: 500px;
    }

    @include mobile() {
      img {
        padding: 0;
      }
      dl {
        padding: 15px 0;
      }
    }
  }

  .features__user-interviews {
    position: relative;
    padding-top: 70px;
    padding-bottom: 80px;
    color: white;
    background-color: #405993;

    .user-interviews {
      position: static;
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
    }

    .slick-arrow {
      position: absolute;
      width: 70px;
      height: 70px;
      top: calc(50% - 35px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: center;

      &.slick-prev {
        left: 90px;
        background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/features/carousel-left.png");
      }
      &.slick-next {
        right: 90px;
        background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/features/carousel-right.png");
      }
    }

    h3 {
      margin: 15px auto 10px;
      font-weight: normal;
    }
    dt {
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
    }
    dd {
      margin-top: 40px;
      font-size: 17px;
      line-height: 1.53;
      font-weight: 300;
      color: white;
    }


    @include mobile() {
      padding: 10%;

      .slick-arrow {
        top: 20px;
        &.slick-prev {
          left: 10px;
        }
        &.slick-next {
          right: 10px;
        }
      }
    }
  }

  .features__admins {
    padding-top: 100px;
    padding-bottom: 80px;
    border-bottom: 1px solid #eeeeee;

    .desc {
      margin-bottom: 66px;
    }

    img {
      box-sizing: border-box;
      padding: 0 15px;
      max-width: 500px;
      width: 100%;
    }
    dl {
      padding: 35px 25px;
      max-width: 500px;
    }

    @include mobile() {
      img {
        padding: 0;
      }
      dl {
        padding: 20px 0;
      }
    }
  }

  .features__secures {
    padding-top: 100px;
    padding-bottom: 87px;
    border-bottom: 1px solid #eeeeee;
    background-color: #f9f9f9;

    .desc {
      margin-bottom: 90px;
    }

    dl {
      &:after {
        clear: both;
        content: " ";
        display: block;
      }
    }

    .features__secures--item {
      width: 31.3%;
      float: left;
      padding: 0 1%;
      margin-bottom: 67px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      dt, dd {
        text-align: center;
      }

      dd {
        padding: 0 40px;
        @include mobile() {
          padding: 0;
        }
      }

      img {
        width: 56px;
        height: 56px;
      }
    }
  }

  .features__platforms {
    padding-top: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #eeeeee;

    .features__platforms-download-buttons {
      margin: 40px auto 80px;
      font-size: 16px;
      line-height: 17px;
      font-weight: 500;
      font-family: Roboto;

      .icon-download {
        margin-right: 6px;
      }
      a {
        display: inline-block;
        margin-bottom: 10px;
        padding: 15px 20px;
        background-color: #ba68c8;
        color: white;
        border-radius: 50px;
        text-decoration: none;

        + a {
          margin-left: 5px;
        }
      }
    }

    img {
      width: 100%;
      max-width: 800px;
    }
  }

  .features__reliabilities {
    padding-top: 80px;
    padding-bottom: 100px;

    .desc {
      margin-bottom: 33px;
    }

    a {
      display: inline-block;
      background-color:#5198ff;
      padding: 15px 20px;
      min-width: 300px;
      text-decoration: none;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      color: white;
    }
  }
}
