.price-page {
  color: #3d3d3d;
  text-align: center;

  section .section-content {
    max-width: 1040px;
    margin: 0 auto;
  }
  h3 {
    margin: 0 auto 10px;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.26;
  }
  p {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.52;
    color: #7e8890;
  }

  .headline {
    background: linear-gradient(277deg, #1c7dff, #57a2ff);

    .headline-wrapper {
      margin: 0 auto;
      padding: 57px 0 107px;
      max-width: 1260px;
      width: 100%;
      box-sizing: border-box;
      color: white;
      font-size: 16px;
      line-height: 1.62;
      text-align: left;
      background-image: url('https://t1.kakaocdn.net/agit_resources/images/main/price/pricing-headline-bg.png');
      background-repeat: no-repeat;
      background-size: 600px;
      background-position-x: right;
      background-position-y: -45px;
    }

    h3 {
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  .partners {
    padding: 90px 0 120px;
    background-color: white;
    border-bottom: 1px solid #eee;
    .section-content {
      box-sizing: border-box;
      padding: 0 13px;
    }
    img {
      width: 100%;
    }
  }

  .price {
    padding: 100px 0 125px;
    background-color: #f9f9f9;
    text-align: left;
    color: #222;

    .price-types {
      display: flex;
      align-items: stretch;
      > li {
        position: relative;
        float: left;
        width: 49.1%;
        min-height: 860px;
        box-sizing: border-box;
        background-color: white;
        border: 1px solid #ccd0d3;
        + li {
          margin-left: 1.8%;
        }
        &.price-standard {
          border-color: #c1c4c7;
          .price-header {
            background-color: #858585;
          }
          .price-content li {
            background-image: url('https://t1.kakaocdn.net/agit_resources/images/main/price/check-gray.png');
          }
        }
        &.price-premium {
          border-color: #a9c1e8;
          .price-header {
            background-color: #5491f5;
          }
          .price-content li {
            background-image: url('https://t1.kakaocdn.net/agit_resources/images/main/price/check-blue.png');
          }
        }
      }

      .price-header {
        padding: 26px 30px;
        color: white;
        h4 {
          font-size: 20px;
          line-height: 1.3;
        }
        span {
          font-size: 15px;
          font-weight: 300;
          line-height: 1.47;

          &:lang(ja) {
            font-size: 12px;
          }
        }
      }

      .price-content {
        background-color: white;
        padding: 40px 30px;
        h5 {
          margin-bottom: 17px;
          font-size: 18px;
          line-height: 1.16;
          color: #1d2129;
        }
        ul {
          margin-bottom: 40px;
          padding-left: 0;
          list-style: inherit;

          li {
            padding-left: 5px;
            list-style: inherit;
            font-size: 15px;
            line-height: 2.15;
            margin: 0;
            padding: 0 0 0 34px;
            list-style: none;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 14px;

            &:lang(ja) {
              font-size: 12px;
            }
            &:lang(en) {
              font-size: 13px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        .price-special-help {
          margin-top:126px;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 22px;

          &:lang(ja) {
            margin-bottom: 15px;
          }
        }
      }

      .price-footer {
        display: block;
        padding: 20px;
        background-color: #5491f5;
        line-height: 17px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        color: white;

        .icon-download {
          margin-right: 6px;
        }
      }
      &:after { clear: both; display: table; content: " "; }
    }
  }

  .consulting {
    padding: 100px 0 319px;
    background-color: white;

    form {
      margin: 0 auto;
      max-width: 800px;
      margin-bottom: 57px;
      text-align: left;

      .form-check {
        margin-bottom: 20px;
        input {
          margin-right: 8px;
        }
        a {
          color: #497bd8;
          margin-left: 5px;
          text-decoration: underline;
        }
      }

      .form-group {
        margin-bottom: 30px;
        label {
          margin: 0 0 6px;
          display: block;
          font-size: 16px;
          line-height: 19px;

          .badge-required {
            margin-left: 4px;
            color: red;
          }
        }
        .form-control {
          width: 100%;
          padding: 11px 10px;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 1.63;
        }
        .text-error {
          margin-top: 5px;
        }
      }
      button {
        padding: 20px;
        width: 100%;
        background-color: #5491f5;
        border-color: #007aff;
        color: white;
        font-size: 18px;
        line-height: 1.16;
        &:disabled {
          opacity: .8;
        }
      }
      textarea.multiline-blur {
        color: #aeaeae;
      }
    }

    .consulting-help {
      font-size: 16px;
      color: #757575;
    }
  }
}
