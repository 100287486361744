// Font Families
$baseFontFamily: "Apple SD Gothic Neo", "AppleHelvetica Neue", "Malgun Gothic",
  Helvetica, Arial, sans-serif;
$helFontFamily: "Helvetica Neue", Helvetica, Arial, sans-serif;

// Text Colors - Emphasis
$emph1-Color: #f44336;
$emph2-Color: #27ae60;
$emph3-Color: #3f6dca;
$emph4-Color: #4778d9;
$emph5-Color: #5985e0;
$emph6-Color: #e94a35;
$emph7-Color: #468847;

// Text Colors
$text1-Color: #000;
$text2-Color: #1f1f1f;
$text3-Color: #3d495a;
$text4-Color: #8d99ac;
$text5-Color: #4e637d;
$text6-Color: #555459;
$text7-Color: #666;
$text8-Color: #999;
$text11-Color: #333;
$text12-Color: #bbb;

// Line Colors
$Line1-Color: #dcdfe4;
$Line2-Color: #ccc;
$Line3-Color: #dcdcdc;
$Line4-Color: #81a7ec;
$Line5-Color: #dbdbdb;
$Line6-Color: #f3f3f3;
$Line7-Color: #fff;
$Line8-Color: #ececec;

// Element Colors
$btn-accent-Color: #497bd8;
$btn-disabled-Color: #bfbfbf;
$btn-white-Color: #fff;

// BG Colors
$bg1-Color: #f9fafc;

.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.background-cover {
  -webkit-background-size: cover;
  background-size: cover;
}

//
@mixin dpr($dpr) {
  @media only screen and (-webkit-min-device-pixel-ratio: $dpr),
    only screen and (min-device-pixel-ratio: $dpr) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 456px) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin disable_ios_input_style {
  border-radius: 0;
  -webkit-appearance: none;
}
