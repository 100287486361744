.security-page {
  color: #3d3d3d;
  text-align: center;

  section {
    background-color: white;
    border-bottom: 1px solid #eee;

    .section-content {
      max-width: 1040px;
      margin: 0 auto;
    }
  }
  h3 {
    margin: 0 auto 10px;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.26;
  }
  p {
    margin-bottom: 60px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.52;
    color: #7e8890;
  }

  .headline {
    width: 100%;
    box-sizing: border-box;
    background-color: #464646;
    border-bottom: none;
    color: white;
    font-size: 16px;
    line-height: 1.62;
    text-align: left;

    .headline-wrapper {
      margin: 0 auto;
      padding: 57px 0 47px;
      max-width: 1260px;
      background: url('https://t1.kakaocdn.net/agit_resources/images/main/security/security-headline-bg.png.png');
      background-repeat: no-repeat;
      background-size: 360px;
      background-position-x: right;
      background-position-y: 40px;
    }

    h3 {
      margin: 0 0 30px;
      font-weight: 500;
    }
    p {
      margin-bottom: 0;
      color: white;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.62;
    }
  }

  .certifications {
    padding: 80px 0;
    .logo {
      float: left;
      a {
        display: inline-block;
        width: 134px;
        height: 134px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
      }
      + .logo { margin-left: 125px; }
      &:last-child a { width: 240px; }
    }
    .logos:after { clear: both; display: table; content: " "; }
  }

  .security-context {
    padding: 80px 0 40px;
    text-align: left;
    h3 {
      margin-bottom: 60px;
    }
    ul {
      li {
        width: 47.2%;
        float: left;

        h4 {
          margin-bottom: 15px;
          font-size: 18px;
          line-height: 1.16;
          color: #1d2129;
        }
        p {
          margin-bottom: 40px;
          font-size: 16px;
          line-height: 1.75;
          color: #333;
        }
        a {
          color: #5491f5;
          text-decoration: underline;
        }

        + li {
          margin-left: 5.6%;
        }
      }
      &:after { clear: both; display: table; content: " "; }
    }
  }

  .security-contact {
    padding: 80px 0 230px;
    h3 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 33px;
    }
    .contact-button {
      display: inline-block;
      padding: 15px 20px;
      min-width: 260px;
      background-color: #5198ff;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.16;
      color: white;
      text-decoration: none;
    }
  }
}
