@import "../configurations";

.ag-header {
  position: fixed;
  z-index: 10;
  padding: 30px 10px 24px;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;

  @include mobile() {
    padding: 15px 10px;
  }

  .ag-header__content {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;

    &:after {
      clear: both;
      content: " ";
      display: block;
    }

    &.ag-header__content--breadcrumb {
      .ag-header__nav {
        display: none;
      }
    }
  }

  .ag-header__logo {
    float: left;

    .agit-logo,
    .by-kep {
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      height: 27px;
      margin: 5px 0 3px;
      float: left;
    }

    .agit-logo {
      background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/logo.png");
      width: 49px;
    }
    .by-kep {
      background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/by-kakao.png");
      width: 220px;
      margin-left: 7px;
    }

    @include mobile() {
      .agit-logo,
      .by-kep {
        height: 20px;
      }

      .agit-logo {
        width: 36px;
      }
      .by-kep {
        width: 72px;
      }
    }
  }

  .ag-header__nav {
    float: right;

    ul {
      li,
      a {
        display: inline-block;
      }

      a {
        padding: 9px 15px 8px;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        color: #757575;
        text-decoration: none;

        -webkit-transition: color 0.2s;
        transition: color 0.2s;
      }

      .btn-signup {
        margin-left: 25px;
        + .btn-signup {
          margin-left: 9px;
          a {
            background-color: #5491f5;
            font-weight: 400;
            color: white;
          }
        }

        a {
          min-width: 92px;
          box-sizing: border-box;
          border-radius: 50px;
          border: solid 1px #5491f5;
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          color: #5491f5;

          -webkit-transition: background-color 0.2s, color 0.2s;
          transition: background-color 0.2s, color 0.2s;
        }
      }
    }
  }

  &.ag-header-contrast {
    background-color: transparent;
    box-shadow: none;

    .ag-header__logo {
      .agit-logo {
        background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/logo-white.png");
      }
      .by-kep {
        background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/by-kakao-white.png");
      }
    }

    .ag-header__nav {
      ul {
        a {
          color: white;
        }

        .btn-signup {
          + .btn-signup {
            a {
              background-color: white;
              color: #2989db;
            }
          }

          a {
            border-color: white;
            color: white;
          }
        }
      }
    }
  }

  .ag-header__breadcrumb {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    line-height: 69px;
    text-align: center;
    color: #2c2d2d;
    font-size: 16px;
    li {
      display: inline-block;
    }
    li.active {
      color: #4e7dda;
    }
    li:after {
      content: " ";
      background-image: image-url("bootcamp/chevron-right-material.png");
      width: 6px;
      height: 10px;
      display: inline-block;
      margin: 0 15px;
      @include dpr(2) {
        background-image: image-url("bootcamp/chevron-right-material@2x.png");
        background-size: 6px 10px;
      }
      @include dpr(3) {
        background-image: image-url("bootcamp/chevron-right-material@3x.png");
        background-size: 6px 10px;
      }
      @include mobile() {
        margin: 0 10px;
      }
    }
    li:last-child:after {
      display: none;
    }

    @include mobile() {
      font-size: 13px;
      line-height: 35px;
      top: 57px;
      text-align: center;
      border-top: solid 0.5px #d3d3d3;
      border-bottom: solid 0.5px #d3d3d3;
      background: #f9f9f9;
    }
  }
}
