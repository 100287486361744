#privacy, #terms {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  font-size: 14px;
  line-height: 23px;
  color: #6d6d6d;

  h2 {
    font-size: 30px;
    line-height: 36px;
    color: #3d495a;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  p, ul {
    margin-bottom: 10px;
    text-align: justify;
  }
  ul {
    padding-left: 30px;
    li {
      list-style-type: disc;
    }
  }

  table {
    width: 100%;
    border: 1px solid gray;
    border-spacing: 0;
    text-align: center;
    td, th { padding: 10px; }
    th { font-size: 16px; }
    td { border-top: 1px solid gray; }
  }
  .comment {
    margin: 8px 0 40px;
    font-size: 12px;
  }
  a { text-decoration: underline; }

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }

  @media (max-width: 456px) {
    font-size: 13px;
    h2 {
      font-size: 22px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .comment {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

