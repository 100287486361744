

/*









 */

@import "configurations";
@import "sweetalert";

$baseFontFamily: "Apple SD Gothic Neo", "AppleHelvetica Neue", "Malgun Gothic",
  Helvetica, Arial, sans-serif;

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
input,
select,
textarea,
button {
  vertical-align: middle;
}
input::-ms-clear {
  display: none;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
body {
  background: #f9f9f9;
}
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 14px;
  line-height: 1.5;
  font-family: $baseFontFamily;
  color: #333;
}
a {
  color: #333;
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: underline;
}
a:active {
  background-color: transparent;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}
input[disabled] {
  cursor: default;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// global
#agIndex {
  overflow: hidden;
  position: absolute;
  left: -9999px;
  width: 0;
  height: 1px;
  margin: 0;
  padding: 0;
}
.screen-out {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.clearfix {
  clear: both;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

*:lang(ja) {
  font-family: "Hiragino Kaku Gothic ProN", "Meiryo", "sans-serif";
}

// Basic Layout
html,
body {
  width: 100%;
  height: 100%;
}

.agit {
  position: relative;
  height: 100%;
  .inner-ag {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }

  background-color: #fff;
}

.ag-content {
  min-height: calc(100vh - 286px - 70px);

  @include mobile() {
    min-height: calc(100vh - 57px);
  }

  .ag-content__article > div {
    margin-top: 90px;
    @include mobile() {
      margin-top: 57px;
    }
  }

  .agit-error {
    display: block;
    height: 300px;
    padding: 100px 20px;
    text-align: center;
    vertical-align: middle;
    h3 {
      color: #e34840;
      font-weight: normal;
    }
    p {
      color: #888888;
    }
  }

  .ag-submit {
    width: 100%;
    height: 54px;
    background-color: $emph4-Color;
    border: solid 1.2px #3f6dca;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;

    &[disabled] {
      color: rgba(255, 255, 255, 0.5);
    }

    @include mobile() {
      height: 45px;
      font-size: 15px;
    }
  }

  .text-error {
    font-size: 14px;
    line-height: 19px;
  }

  .ag-rounded-centered-content {
    width: 802px;
    text-align: center;
    margin: 100px auto;

    a {
      color: $emph4-Color;
      text-decoration: underline;
    }

    .ag-rounded-centered-content__body {
      margin-bottom: 20px;
      border-radius: 8px;
      background-color: #ffffff;
      border: solid 1px #dcdfe4;
    }

    .ag-rounded-centered-content__headline {
      padding: 60px 70px;
      text-align: center;
      h2 {
        display: inline-block;
        font-size: 30px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 20px;
        max-width: 420px;
      }
      p {
        font-size: 16px;
        line-height: 1.88;
        color: #808080;
        margin-bottom: 20px;
      }

      ul.list-inline {
        text-align: center;
        li.list-inline-item {
          display: inline-block;
          padding: 23px 23px 8px;
          vertical-align: top;
          min-width: 64px;
          max-width: 64px;
          img,
          a {
            display: block;
          }
          img {
            max-width: 64px;
          }
          a {
            margin-top: 6px;
            margin-bottom: 4px;
            color: $emph4-Color;
            font-weight: bold;
            text-decoration: underline;
          }
          small {
            color: #666;
          }
        }
      }
    }

    .ag-rounded-centered-content__sub {
      padding: 40px 70px 30px;
      text-align: left;
    }
  }
}

.icon-movie-play {
  display: inline-block;
  background-size: contain;
  background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/features/movie-play.png");
  width: 84px;
  height: 84px;
}

.icon-quotes {
  display: inline-block;
  background-size: contain;
  background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/features/quotes.png");
  width: 58px;
  height: 50px;
}

.icon-download {
  display: inline-block;
  background-size: contain;
  background-image: url("https://t1.kakaocdn.net/agit_resources/images/main/features/platform-download.png");
  width: 10px;
  height: 12px;
}

@include mobile() {
  .ag-header__nav,
  footer {
    display: none;
  }
}

/* ----------------------------------------------------------------------------
 * 행성 초대 오류 페이지 UI
 */

.planet-invitations-error-pages {
  position: relative;
  background-color: $emph4-Color;
  text-align: center;
  width: 100%;
  min-height: calc(100vh - 286px - 90px);
  margin: 0 auto;

  @include mobile() {
    min-height: calc(100vh - 57px);
  }

  .error-contents {
    position: relative;
    z-index: 1;

    h2 {
      padding-top: 150px;
      color: #fff;
      font-weight: 300;
    }

    h3 {
      color: #676767;
      margin-bottom: 30px;
      font-weight: normal;
    }

    a {
      color: #fff;
      display: inline-block;
      padding: 11px 32px;
      border: 1px solid #fff;
      border-radius: 50px;
      background-color: transparent;
      line-height: 1.3;
      vertical-align: top;
      text-decoration: none;
    }
  }

  .desc-img {
    position: absolute;
    bottom: 0;
    margin: 0 auto;

    @include mobile() {
      width: 200px;
      bottom: 0;
    }
  }
}

/* ------------------------------------------------------------------------- */
/* intro.css migration */

// common images
.ico {
  display: block;
  overflow: hidden;
  background: image-url("intro/img-intro.png") no-repeat;
  text-indent: -9999px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 1.5dppx) {
  .ico {
    background-image: image-url("intro/img-intro.png");
    -webkit-background-size: 460px 460px;
    background-size: 460px 460px;
  }
}

h2 {
  strong {
    color: $emph4-Color;
    margin-right: 6.5px;
    font-weight: 600;
  }
}

.text-success {
  color: $emph4-Color;
}
.text-warning {
  color: $emph7-Color;
}
.text-error {
  color: $emph1-Color;
}
.text-center {
  text-align: center;
}

// 로그인, 가입, 개설 base common
.agit-account {
  box-sizing: border-box;
  padding: 100px 10px 110px;
  text-align: center;
  margin: 0 auto;
  max-width: 520px;
  width: 100%;

  h3 {
    font-weight: 200;
    font-size: 30px;
    line-height: 1.2;
    color: $emph4-Color;
    margin-bottom: 12px;
  }

  .desc {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: $text6-Color;
  }

  form {
    .single-input-text {
      margin-bottom: 10px;
      input[type="text"] {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 18px 19px;
        border: 1px solid #e2e2e2;
        font-size: 16px;
        line-height: 1.2;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $text12-Color;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $text12-Color;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $text12-Color;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $text12-Color;
        }
      }
    }

    input[type="submit"] {
      box-sizing: border-box;
      padding: 20px 18px 19px;
      background-color: $emph3-Color;
      color: white;
      font-size: 16px;
      line-height: 1.2;
      &:disabled {
        opacity: 0.5;
      }
    }

    &.find-your-agit,
    &.forgot-password {
      margin-top: 34px;
    }
    &.find-your-agit {
      margin-bottom: 70px;
    }
  }

  .done-circle {
    width: 80px;
    height: 80px;
    background-image: url("https://t1.kakaocdn.net/agit_resources/images/done.svg?v=1");
    background-size: cover;
  }

  .text-success,
  .text-error {
    padding: 6px 0 17px;
    font-size: 14px;
    line-height: 16px;
  }

  .ico-expired-link {
    width: 75px;
    height: 75px;
  }

  .expired-link__title {
    margin-top: 20px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.2;
    color: #333333;
  }

  .expired-link__desc {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.4;
    color: #808080;
  }

  .expired-link-form {
    max-width: 400px;
    margin: 0 auto;
  }

  .text-error {
    &.password-strength {
      display: none;
      &.good {
        color: #4b7dd8;
      }
      &.strong {
        color: #468847;
      }
    }
    .fa {
      margin-right: 5px;
      font-size: 15px;
    }
  }

  &.email-verification {
    max-width: 520px;
  }

  &.find-your-agit-done,
  &.forgot-password-done {
    .done-circle {
      margin: 20px auto;
    }
    .desc {
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .agit-account {
    padding-top: 35px;
    padding-bottom: 30px;
  }
}

.box-groups {
  border: 1px solid $Line2-Color;
  border-radius: 3px;
  background-color: #fff;
}

// 아지트 소개
.agit-intro {
  .ag-content {
    padding-top: 0;
    section {
      text-align: center;
    }
  }
}

.intro-signup {
  display: table;
  width: 100%;
  height: 240px;
  color: #fff;
  background: image-url("intro/bg-intro-signup.png") no-repeat 50% 50%;
  .inner-intro-signup {
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
  }
  .intro-signup__tit {
    padding-top: 10px;
    font-weight: 400;
    font-size: 20px;
  }
  .intro-signup__desc {
    padding-bottom: 20px;
    font-weight: 100;
  }
  .btn-signup {
    display: block;
    width: 118px;
    height: 38px;
    margin: 0 auto;
    border: 1px solid #fff;
    border-radius: 100px;
    font-weight: 400;
    line-height: 39px;
    color: #fff;
    text-decoration: none;
  }
}

// input text common
.ra-input {
  height: 48px;
  border: 1px solid $Line2-Color;
  border-radius: 3px;
  background-color: #fff;
  input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px 16px 14px;
    border: 0 none;
    font-size: 15px;
    line-height: 21px;
    background-color: transparent;
    box-sizing: border-box;
    &[disabled] {
      color: #b7b7b7;
      background-color: #f3f3f3;
    }
  }
  &.ra-input--disabled {
    color: #b7b7b7;
    background-color: #f3f3f3;
  }
}
.custom-groupbox {
  position: relative;
  .ra-input {
    margin-right: 100px;
  }
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
  }
}
.ra-label {
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: #2d2d2d;
}
.ra-input-help {
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
  color: $text8-Color;
}
// checkbox common
.ra-choice {
  line-height: 0;
  $Line5-Color: #ccc;
  $btn-accent-Color: #497bd8;
  .ra-checkbox {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: top;
    input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: none;
      opacity: 0.01;
      cursor: pointer;
      -webkit-appearance: none;
    }
    .inp-check {
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid $Line5-Color;
      pointer-events: none;
      .ico {
        display: block;
        width: 12px;
        height: 8px;
        margin: 4px auto 0;
        background-position: -180px -20px;
      }
    }
    input:checked + .inp-check,
    &.ra-checkbox--active .inp-check {
      border-color: $btn-accent-Color;
      background-color: $btn-accent-Color;
    }
    input:focus + .inp-check {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
  label {
    display: inline-block;
    padding: 3px 0 0 11px;
    font-size: 15px;
    line-height: 1;
    color: $text8-Color;
    vertical-align: top;
    cursor: pointer;
  }
  &.ra-choice-circle {
    .ra-checkbox {
      width: 27px;
      height: 27px;
      .inp-check {
        width: 25px;
        height: 25px;
        border-radius: 27px;
        .ico {
          margin-top: 9px;
          background-position: -180px 0;
        }
      }
      input:checked + .inp-check .ico,
      &.ra-checkbox--active .inp-check .ico {
        background-position: -180px -20px;
      }
    }
    label {
      padding-top: 7px;
      color: #333;
    }
  }
}

// button common
.btn {
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  padding: 11px 5px 10px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    text-decoration: none;
  }
  + .btn {
    margin-left: 5px;
  }
}

.btn-block {
  display: block;
  width: 100%;
  + .btn-block {
    margin-left: 0;
  }
}
.btn-warning {
  border-color: transparent;
  color: #fff;
  background-color: $btn-accent-Color;
  &[disabled] {
    background-color: $btn-disabled-Color;
  }
}
.btn-disabled {
  background-color: $btn-disabled-Color;
}
.btn-link {
  border-color: $Line3-Color;
  color: $text8-Color;
  background-color: transparent;
}
.btn-text {
  display: inline-block;
  padding: 10px 4px;
  border: none;
  background-color: transparent;
  line-height: 1.2;
  color: $text7-Color;
}
.btn-default {
  border-color: $Line8-Color;
  color: $text8-Color;
  background-color: $btn-white-Color;
}
.btn-sm {
  padding: 9px 8px 7px;
  min-width: 50px;
  height: 32px;
  line-height: 17px;
  font-size: 13px;
  border-radius: 2px;
}
.btn-lg {
  padding: 16px 10px 15px;
  font-size: 15px;
}

.desc-img {
  position: absolute;
  bottom: 111px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.table-list {
  margin: 13px 0 30px;
  width: 100%;
  border: 1px solid #dcdfe4;
  border-collapse: collapse;

  thead td {
    background-color: #f9f9f9;
    line-height: 15px;
    font-size: 12px;
    color: #333333;
    border-bottom: 1px solid #dcdfe4;
    box-shadow: 0 1px 0 0 #dcdfe4;
    padding: 7.5px 20px;
  }

  tbody {
    background-color: #fff;
    td {
      border-bottom: 1px solid #f3f3f3;
      padding: 16px 20px;
      line-height: 16px;
      font-size: 14px;
      &:last-child {
        font-size: 12px;
      }
      a {
        color: #262626;
      }
    }
    tr:last-child td {
      border-bottom: none;
    }
  }

  .text-center {
    text-align: center;
  }
}

.table-detail {
  margin-top: 8px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid $Line1-Color;
  h4 {
    padding: 20px 19px 20px 20px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 16px;
    line-height: 20px;

    .date {
      font-weight: normal;
      font-size: 12px;
      color: #333333;
    }
  }
  .summary {
    padding: 21px 30px 20px 20px;
    min-height: 350px;
    color: #1f1f1f;
    font-size: 14px;
    line-height: 1.6;

    .link-for-press {
      display: block;
      margin-top: 10px;
      color: #497bd8;
      text-decoration: underline;
    }
  }
}

/* ----------------------------------------------------------------------------
 * 이 브라우저에서 로그인했던 아지트 컴포넌트
 */
.visited-planets-title {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  line-height: 21px;
  font-size: 16px;
  font-weight: normal;
  color: #555459;
  margin-bottom: 33px;
  @include mobile() {
    font-size: 13px;
    margin-bottom: 28px;
  }
}

.visited-planets {
  li {
    position: relative;
    height: 70px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    border: solid 1px #e2e2e2;
    padding: 18px 15px 19px 20px;
    margin-bottom: 10px;

    .planet-name {
      text-align: left;
      line-height: 19px;
      font-size: 15px;
      font-weight: bold;
      color: #2c2d30;
    }
    .planet-domain {
      text-align: left;
      line-height: 14px;
      font-size: 12px;
      color: #9e9e9e;
    }
    a.login-button {
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px #e0e0e0;
      line-height: 16px;
      font-size: 13px;
      padding: 8px 17px;
      font-weight: 600;
      color: #5e5e5e;
      position: absolute;
      top: 17px;
      right: 15px;
      text-decoration: none;
    }
  }
}
/*
 * End of 이 브라우저에서 로그인했던 아지트 컴포넌트
 * ------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------
 * commons
 *
 * 더 이상 참지 못하고 스타일 기준으로 만든 클래스들
 */

.content-wrap {
  &.content-wrap-center {
    margin: 0 auto;
  }
  &.content-wrap-small {
    width: 500px;
    @include mobile() {
      width: calc(100% - 20px);
    }
  }
  &.content-wrap-message {
    padding: 130px 0;
    @include mobile() {
      padding: 35px 10px 0;
    }
  }
}

.logo-exclamation {
  width: 80px;
  height: 80px;
  background-image: url("https://t1.kakaocdn.net/agit_resources/images/logo_exclamation.svg");
  background-size: cover;
}

.done-circle {
  width: 80px;
  height: 80px;
  background-image: url("https://t1.kakaocdn.net/agit_resources/images/done.svg?v=1");
  background-size: cover;
}

.title {
  line-height: 36px;
  font-size: 30px;
  text-align: center;
  font-weight: 300;
  color: #000000;

  &.title-primary {
    font-weight: 600;
    color: #4778d9;
  }
}

.message {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #555459;
}

.button {
  display: inline-block;
  background-color: #ffffff;
  border: solid 1.4px #e0e0e0;
  padding: 20px 20px 19px;
  line-height: 19px;
  font-size: 16px;
  text-align: center;
  color: #5e5e5e;
  cursor: pointer;
  box-sizing: border-box;
  &:hover,
  &:active {
    text-decoration: none;
  }

  &.button-fluid {
    width: 100%;
  }

  &.button-primary {
    background-color: #4778d9;
    border: solid 1.4px #3f6dca;
    color: #fff;
  }
}

.glitch-bg .ag-content {
  background-color: #262456;
}
