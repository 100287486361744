@import "../configurations";

.error-pages {
  .error-contents {
    position: relative;
    width: 100%;
    min-height: 500px;
    color: #fff;
    text-align: center;

    @include mobile() {
      min-height: 200px;
    }

    h2 {
      margin: 0 auto 10px;
      padding-top: 60px;
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;

      @include mobile() {
        font-size: 24px;
      }
    }
    h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 100;
      margin: 10px auto 18px;
      color: rgba(255, 255, 255, 0.7);
    }
    a,
    input[type="submit"] {
      display: inline-block;
      padding: 11px 32px;
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 50px;
      background-color: transparent;
      line-height: 1.3;
      vertical-align: top;
    }
    input[type="submit"] {
      margin-right: 15px;
    }
  }

  &.glitch {
    position: relative;
    h2 {
      padding-top: 160px;
    }
    img {
      position: absolute;
      top: -120px;
      left: 0;
      width: 100%;
    }
  }

  .request-agit-go {
    margin-top: 40px;
    button {
      color: #fff;
    }
  }
}

.glitch-bg {
  @extend .error-page-main-area;
}
.unpermitted-bg {
  @extend .error-page-main-area;
  .agit {
    background-color: #5a86dd;
  }
}
.error-page-main-area {
  .ag-content {
    background-image: image-url("errors/planets.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 90px;
    .ag-content__article {
      vertical-align: middle;
    }
  }
}
$glitch-page-margin: 260px;
$glitch-page-contents-padding: 240px;
$unpermitted-page-margin: 180px;
$unpermitted-page-contents-padding: 140px;
