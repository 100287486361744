@import "../configurations";

footer {
  background-color: #464646;
  padding: 50px 10px;
}

.ag-footer {
  margin: 0 auto;
  max-width: 1020px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #ddd;

  ul {
    float: left;
    width: 25%;
    margin-bottom: 10px;
  }

  strong {
    display: inline-block;
    margin-bottom: 8px;
  }

  a {
    font-size: 14px;
    line-height: 1.71;
    color: #aaa;
  }

  hr {
    margin: 30px auto;
    border: none;
    height: 1px;
    width: 100%;
    opacity: .5;
    background-color: #787878;
  }

  .ag-footer__corp {
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
    color: #ccc;
  }


  .ag-footer__mobile {
    width: 400px;
    margin: 0 auto;
    padding: 20px 0 0 0px;
    .icon-image {
      display: inline-block;
      float: left;
      width: 70px;
      height: 70px;
    }
    .main-text {
      display: inline-block;
      margin: 10px 0 0 10px;
      color: #333;
      font-size: 19px;
      line-height: 25px;
    }
    .button-container {
      clear: both;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 14px;
      text-align: center;

      a {
        display: inline-block;
        width: 100%;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-size: 13px;
        line-height: 45px;
        text-decoration: none;

        &.install-app-button {
          background: $emph4-Color;
        }

      }
    }
    .sub-text {
      clear: both;
      color: #808080;
      font-size: 14px;
      text-align: center;
    }
  }
}
